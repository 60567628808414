body, html{
  height: 100%;
  margin: 0;
}

body {
background-color: white;
color: #202124;
font-family: 'Libre Franklin', sans-serif;
font-size: 20px;
}