.content {
  padding-top: 40px;
}

.event-list {
  list-style: none;
}

.map-container {
  height: 30vh;
}

.iframe-trigger {
  text-align: center;
  padding: 0 !important;
}

.title-sponsor-img {
  width: 40%;
}

.trigger {
  background-color: #4b8bbf;
}

.btn:focus {
  background-color: #4b8bbf;
}

.btn:hover {
  background-color: #4b8bbf;
  opacity: 0.9;
}

.event-description::first-letter {
  float: left;
  font-family: "Special Elite", cursive;
  font-size: 5.2em;
  line-height: 60px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.formStack:hover {
  opacity: 0.8;
  text-decoration: underline;
}

@media only screen and (max-width: 450px) {
  .iframe-trigger {
    margin-bottom: 20px;
  }

  .title-sponsor-img {
    width: 50%;
  }
}
