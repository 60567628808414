.bga-logo {
  width: 125px;
}

.driehausLogo {
  width: 240px;
}

.logos-container {
  height: 205px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
}

.title-sponsor p {
    margin-block-start: 0;
}

.fa-facebook-f {
  font-size: 2rem;
  color: #3B5998;
}

.fa-twitter {
  font-size: 2rem;
  color: #00ACEE;
}

/* .fab {
  padding: 0 2rem;
} */

@media (max-width: 576px) {
  .logos-container {
    flex-direction: column;
  }

  .title-sponsor {
      text-align: center;
  }

  .bga-container {
      text-align: center;
  }
}
