.nav-container {
  position: relative;
}

.nav-bar {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  height: 64px;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  z-index: 200;
  background-color: white;
  overflow: hidden;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.spacer {
  flex: 1;
}

.nav-bar .brand-link {
  height: 100%;
  padding: 0.8rem 1.2rem;
}

.nav-bar img {
  width: 80px;
}

.nav-bar img:hover {
  filter: brightness(1.5);
}

.nav-bar ul {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  font-family: "Special Elite", cursive;
}

.nav-bar li {
  padding: 0.5rem 1rem;
}

.navLinks a {
  color: #fa2929;
}

.navLinks a:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.sticky + .content {
  padding-top: 104px;
}
