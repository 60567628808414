.dropdown {
    background-color: #202124;
    height: 50px;
    z-index: 1;
    transform: translateY(-100%);
    transition: transform  0.3s ease-out;
    position: absolute;
    width: 100%;
    top: initial;
    /* display: none; */
  }
  
  .dropdown.open {
    transform: translateY(0);
    position: absolute;
    width: 100%;
    top: unset;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    /* display: block; */
  }
  
  .dropdown ul {
    height: 50px;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    padding: 0;
    margin: 0;
    font-family: "Special Elite", cursive;
  }

  .dropdown a {
    color: #F9F7D1;
  }
  