.headerImg {
    width: 100%;
    max-height: 100vh;
    min-height: 35vh;
    display: block;
  }
  
  @media (max-width: 375px) {
    .headerImg {
      min-height: 30vh;
    }
  }
  