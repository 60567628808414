.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30px;
    width: 35px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .btn-container:focus {
    outline: none;
    background: unset;
  }
  
  .btn-line {
    width: 35px;
    height: 5px;
    background: #333;
    border-radius: 10px;
    transition: 0.4s;
  }
  
  .change.bar1 {
    /* -webkit-transform: rotate(-45deg) translate(-9px, 6px); */
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .change.bar2 {
    opacity: 0;
  }
  
  .change.bar3 {
    /* -webkit-transform: rotate(45deg) translate(-8px, -8px); */
    transform: rotate(45deg) translate(-8px, -5px);
  }
  